@import "../config";

// @mixin responsive-table{
//     thead {
//       color: white;
//       display: none;
//     }
    
//     tbody {
//       display: flex;
//       flex-flow: row wrap;
//       width: 100%;
      
//       td {
//         background-color: white;
//         display: block;
//         padding: .75rem 1rem;
//         width: 100%;
  
//         &::before {
//           content: attr(aria-label);
//           display: inline-block;
//           font-weight: bold;
//           margin: 0 1rem 0 0;
//           min-width: 80px;
//         }
  
//         &:first-child {
//           background-color: black;
//           color: white;
//           text-align: center;
  
//           &::before {
//             display: none;
//           }
//         }
//       }
//     }
    
//     tr {
//       display: flex;
//       flex-flow: column wrap;
//       flex: 0 0 100%;
//       padding: 1.25rem 1rem;
//       max-width: 100%;
//       width: 100%;
//     }
    
//     @media screen and (min-width: 600px) {
//       tr {
//         flex: 0 0 50%;
//         max-width: 50%;
//         width: 100%;
//       }
//     }
    
//     @media screen and (min-width: 900px) {
      
//       background-color: #efefef;
      
//       thead {
//         display: table-header-group;
        
//         th {
//           background-color: black;
//           padding: 1rem;
//           text-align: center;
//         }
//       }
      
//       tbody {
//         display: table-row-group;
        
//         td {
//           background-color: transparent;
//           display: table-cell;
//           padding: 1rem;
//           text-align: center;
//           width: auto;
  
//           &::before {
//             display: none;
//           }
  
//           &:first-child {
//             background-color: transparent;
//             color: inherit;
//           }
//         }
//       }
      
//       tr {
//         display: table-row;
//         margin: 0;
        
//         &:nth-child(odd) {
//           background-color: white;
//         }
  
//         &:nth-child(even) {
//           background-color: light gray;
//         }
//       }
//     }
//   }

.responsive-credit-user-table,
.responsive-credit-table,
.basic-information {
    // @include responsive-table
}

.position-sticky:focus,
.position-sticky:visited,
.position-sticky:focus-within {
    background-color: $btn-credit-hover;
    color: $secondary-color;
}

.credit-table {
    margin: 20px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.basic-information {
    border-collapse: collapse;
    background-color: $secondary-color;
    // text-align: center;

    table {
        border: 1px solid black;
        width: 100%;

        tr th {
            background-color: $btn-credit;
            color: $secondary-color;
        }

        tr {
            &:hover {
                background-color: $bg-sidebar;
            }
        }

        tr,
        th,
        td {
            border: 1px solid black;
        }
    }
}

.responsive-credit-user-table {
    background-color: $secondary-color;

    table {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr th {
        background-color: $theme-color;
        color: $secondary-color;
    }

    th,
    td {
        border: 1px solid black;
        text-align: left;
        padding: 8px;
    }
}

.responsive-credit-table {
    background-color: $secondary-color;

    table {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr th {
        background-color: $btn-credit;
        color: $secondary-color;
    }

    th,
    td {
        border: 1px solid black;
        text-align: left;
        padding: 8px;
    }
}