@import "../config";

.customer{
    margin: 15px 10px !important;
}
.customer-info{
    display: flex;
    background-color: $secondary-color;
    border: 3px solid $basic-theme;
    margin: 0px 0px !important;
    border-radius: 10px;
    padding: 20px 10px;
}
.customer-info-input{
    margin: 20px 0px;
}
.radio-type{
    display: flex;
    gap: 5px;
}