@import "../config";

.info-reservation{
    margin: 10px;
    height: auto;
    padding: 10px;
    background-color: #dfd4d4bd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-right{
        display: flex;
    }
    .style-btn-reservation{
        margin: 5px;
        .btn-reservation{
            height: 30px;
            border: 0;
            border-radius: 10px;
            background-color: $theme-color;
            color: $secondary-color;
        }
    }
    .style-btn-availability{
        margin: 5px;
        .btn-availability{
            height: 30px;
            border: 0;
            border-radius: 10px;
            background-color: $bg-availability;
            color: $secondary-color;
        }
    }
    .style-btn-credit{
        margin: 5px;
        .btn-credit{
            height: 30px;
            border: 0;
            border-radius: 10px;
            background-color: $btn-credit;
            color: $secondary-color;
        }
    }
}
@media screen and (max-width: 660px){
    .info-reservation{
        flex-direction: column !important;
        align-items: start !important;
    }
    .btn-right{
        flex-direction: column !important;
    }
    .accordion-table-header{
        thead{
            font-size: 12px;
        }
    }
}
.flex-filter-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $bg-filter;
    height: 150px;
    margin: 10px;
    padding: 10px;

    label {
        padding-left: 5px;
    }
}

.filter-accordions {

    // margin: 20px 10px;
    // padding: 10px;
    label {
        padding-left: 5px;
    }
}
.handle-checkbox{
    margin: 10px 0px;
}
.sorting-input input[type="text"] {
    border-radius: 05px;
    border: 0;
    height: 40px;
    width: 300px;
}
.start-date input[type="text"], .end-date input[type="text"]{
    border-radius: 05px;
    border: 0;
    height: 35px;
    width: 200px;
}

.date-range-picker {
    display: flex;
    gap: 10px;
}


@media screen and (max-width:933px) {
    .date-range-picker {
        flex-direction: column !important;
    }
    .start-date input[type="text"], .end-date input[type="text"]{
        height: 30px;
        width: 150px;
    }
}

@media screen and (max-width:873px) {
    .flex-filter-sort {
        height: auto;
        flex-direction: column !important;
        align-items: start !important;
    }

    .sorting-input input[type="text"] {
        width: 200px;
    }

    .filter-accordions {
        display: flex;
        gap: 10px;
    }
    .start-date input[type="text"], .end-date input[type="text"]{
        width: 200px;
    }
}

.btn-filter {
    margin: 10px;
    display: flex;
    justify-content: end;

    .Filter {
        text-align: center;
        height: 35px;
        width: 100px;
        background-color: $secondary-theme;
        color: $secondary-color;
        border: 0;
        border-radius: 10px;
    }
}