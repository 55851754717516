@import "../config";

.logo {
    height: 60px;
    width: 60px;
    border: 1px solid white;
}

.navbar {
    background-color: $theme-color-light;
    color: $secondary-color !important;
}

.make-reservation a {
    text-decoration: none;
    color: $secondary-color !important;
}

.navbar-collapse {
    padding-top: 15px;
}

.navbar-brand {
    margin-left: 10px;
    color: $secondary-color !important;
}

@media screen and (min-width:768px){
    #responsive-navbar-nav {
        display: flex;
        gap: 10px;
        color: $secondary-color;
    }
}