@import "../config";
.error{
    color: $danger-color;
    text-align: center;
}
.banquet-reservation{
    background-color: #dfd4d4bd;
    // margin: 5px 10px !important;
}
.banquet-reservation-info{
    display: flex;
    // border-radius: 10px;
    // background-color: $secondary-color;
    padding: 20px 10px;
}
.reservation-info{
    margin: 15px 0px;
}